.aditionalDescription{
    margin: 16px 0 49px 0;
    color: var(--gray7);
    font-size: var(--subtitleSize2);
    line-height: var(--subtitleLineHeight1);
}
.personalizedProgramButtons{ 
    width: 100%;
    margin: 90px 0 40px 0;
    display: flex;
    justify-content: space-between;
}