.personalizedProgramContainer{
    width: 100%;
    min-height: 94vh;
    display: flex;
    flex-wrap: wrap;
    background: var(--gray1);
}
.personalizedProgramContent{
    display: flex;
    flex-direction: column;
    width: 400px;
    flex-grow: 1;
    min-height: 100%;
    padding: 160px 5% 0 5%;
}
.personalizedProgramTitle{
    color: var(--gray8);
    font-weight: var(--fontWeightBolder);
    font-size: var(--titleSize3);
    line-height: var(--titleLineHeight2);
    margin-bottom: 8px;
}
.personalizedProgramDescription{
    color: var(--gray7);
    font-size: var(--textSize1);
    line-height: var(--subtitleLineHeight2);
    margin-bottom: 40px;
    padding-top: 0;

}
.personalizedProgramBtn{
    width: 100%;
    margin: 157px 0 40px 0;
    display: flex;
    justify-content: flex-end;
    
}
.personalizedProgramImgContainer{
    width: 400px;
    flex-grow: 1;
    padding: 20px 20px 20px 0;
    box-sizing: border-box;
   
}
.personalizedProgramImg{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

@media (min-width: 320px) and (max-width:600px) {
    .personalizedProgramContent{ 
        padding: 120px 5% 0 5%;
    }   
}