.benefitsContainer {
    width: 100%;
    padding: 160px 5%;
    display: flex;
    justify-content: center;
    background: var(--gray1);
    text-align: center;
    gap: 64px;
}

.titleDescription {
    font-family: LarseitBold;
    color: var(--gray9);
    font-size: 40px;
    line-height: var(--titleLineHeight2);
}


.text {

    color: #262626;
    font-size: 20px;
}

.benefitsContainer img {
    width: 300px;
    height: 500px;
    flex-grow: 1;
    object-fit: contain;
}

.benefitsContainer div {
    width: 300px;
    flex-grow: 2;
    text-align: left;

}

/* PC */
@media (max-width: 1050px) {
    .benefitsContainer {
        padding: 80px 0%;
    }
}

/* Mobile */
@media (max-width:525px) {
    .benefitsContainer {
        flex-wrap: wrap-reverse;
        padding: 80px 5%;
    }

    .titleDescription {
        font-size: 32px;
        line-height: 40px;
    }

    .benefitsContainer img {

        height: auto;

    }
}