.questions {
    padding: 160px 10% 80px 10%;
    font-size: 18px;
    background-color: var(--gray1);
}

.questions p {
    color: #2D3035;
}

.title {
    color: black;
    font-size: var(--titleSize2);
    font-weight: var(--fontWeightBolder);
}

.questions h3 {
    font-size: 24px;
    color: black;
}

.space {
    padding: 120px 0 0 0;
}

.boxAsk {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
}

.boxAsk div {
    width: 500px;
    flex-grow: 1;
    padding: 2% 4% 2% 0;
}

.callAction {
    text-align: center;
    margin-top: 40px;
}