@import url(https://fonts.googleapis.com/css2?family=Paytone+One&display=swap);
:root {
  /* Primary Colors */
  --orange1: rgba(255, 242, 232, 1);
  --orange2: rgba(255, 216, 191, 1);
  --orange3: rgba(255, 187, 150, 1);
  --orange4: rgba(237, 116, 74, 1);
  --orange5: rgba(233, 81, 29, 1);
  --orange6: rgba(233, 81, 29, 1);
  --orange7: rgba(210, 73, 26, 1);
  --orange8: rgba(163, 57, 20, 1);
  --orange9: rgba(140, 49, 17, 1);
  --orange10: rgba(70, 24, 9, 1);
  /* Nueutral Color Palette */
  --gray1: rgba(255, 255, 255, 1);
  --gray2: rgba(250, 250, 250, 1);
  --gray3: rgba(245, 245, 245, 1);
  --gray4: rgba(245, 245, 245, 1);
  --gray5: rgba(217, 217, 217, 1);
  --gray6: rgba(217, 217, 217, 1);
  --gray7: rgba(140, 140, 140, 1);
  --gray8: rgba(77, 75, 75, 1);
  --gray9: rgba(38, 38, 38, 1);
  --gray10: rgba(45, 48, 53, 1);
  /* Extra Colors */
  --backgroundLayout: rgba(240, 242, 245, 1);
  --backgroundMenu1: rgba(0, 21, 41, 1);
  --backgroundMenu2: rgba(0, 21, 41, 1);

  /* Font Weights*/
  --fontWeightNormal: 400;
  --fontWeightSemiBold: 500;
  --fontWeightBold: 700;
  --fontWeightSemiBolder: 800;
  --fontWeightBolder: 900;

  /* Font sizes */
  --titleSize1: 60px;
  --titleSize2: 48px;
  --titleSize3: 36px;
  --subtitleSize1: 24px;
  --subtitleSize2: 18px;
  --textSize1: 16px;
  --textSize2: 14px;
  --noteSize: 12px;

  /* Line Height */
  --titleLineHeight1: 64px;
  --titleLineHeight2: 52px;
  --titleLineHeight3: 40px;
  --subtitleLineHeight1: 28px;
  --subtitleLineHeight2: 22px;
  --textLineHeight1: 20px;
  --textLineHeight2: 18px;
  --notesLineHeight: 16px;

  /* Spacing */
  --spacing-06: 54px;
  --spacing-05: 48px;
  --spacing-04: 32px;
  --spacing-03: 24px;
  --spacing-02: 16px;
  --spacing-01: 8px;

  /* Radius */
  --buttonRadius: 20px;
  --navBarRadius: 2px;

  /* Box-shadow */
  --boxShadowLenght: 0px 8px 16px;
  --boxShadowColor: rgba(204, 204, 204, 0.25);
}


@font-face {
  font-family: AvenirBlack;
  src: url(/static/media/AvenirLTStd-Black.7ce894e1.otf);
}

@font-face {
  font-family: AvenirBook;
  src: url(/static/media/AvenirLTStd-Book.813557df.otf);
}

@font-face {
  font-family: AvenirRoman;
  src: url(/static/media/AvenirLTStd-Roman.0bfb049d.otf);
}

@font-face {
  font-family: LarseitLight;
  src: url(/static/media/Larsseit-Light.091d6ffd.otf);
}

@font-face {
  font-family: LarseitBold;
  src: url(/static/media/Larsseit-Bold.5b3e11de.otf);
}

@font-face {
  font-family: LarseitMedium;
  src: url(/static/media/Larsseit-Medium.51246751.otf);
}
.SideBar_siderContainer__2Cdpl {
    position: fixed;
    top: 10%;
    left: 0;
    width: 240px;
    height: 100%;
    background: var(--gray10);
    border-radius: 0 24px 0 0;
    z-index: 1;
}
.SideBar_siderContent__304wt {
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px 0 0px 24px;
}
.SideBar_siderContent__304wt img:nth-child(1) {
    margin-bottom: 34px;
    height: 24px;
}
.SideBar_links__1WR9A {
    display: flex;
    margin-left: 4px;
    text-decoration: none; 
    font-size: var(--subtitleSize2);
    color: var(--gray7);
    margin-bottom: 30px;
}
.SideBar_links__1WR9A > :first-child {
    margin-right: 16px;
}
.SideBar_links__1WR9A:last-child {
    color: var(--gray1);
    display: flex;
}
.SideBar_links__1WR9A:hover {
    color:var(--gray1);
}
.SideBar_logOut__26qUJ {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 14px;
}
.SideBar_activeLink__21mFO {
    color: var(--gray1);
}

/* tablet */
 @media (max-width:840px) {
    .SideBar_siderContainer__2Cdpl {
        top: 16%;
    }
 }


.Card_base__116VQ {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(217, 217, 217, 0.1);
    background: var(--gray1);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.Card_content__4AGOE {
    display: flex;
    align-items: center;
}
.Card_title__2jy-h {
    color: var(--gray9);
    font-size: var(--subtitleSize2);
    line-height: var(--subtitleLineHeight2);
    font-weight: var(--fontWeightSemiBold);
    margin: 10px 0 0 0;
}
.Card_subtitle__1uL2g {
    color: var(--gray7);
}
.Card_progressBar__2uQr8 {
    min-width: 200px;
    -webkit-transform: translateX(-35%);
            transform: translateX(-35%);
}

/* Content Locked */
.Card_locked__1f_H_ {
    opacity: 0.5;
}
.Card_imgLocked__19Cu_::after{
    content: url('https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/locked_icon.svg?alt=media&token=31610d93-8cb1-4104-8890-5b1466f23107');
    position: relative;
    top: -50px;
    height: 0;
    display: flex;
    justify-content: center;
    -webkit-filter: brightness(0.2);
            filter: brightness(0.2);
}

/* tablet */
@media (max-width:908px) {
    .Card_progressBar__2uQr8 {
        min-width: 100px;
        -webkit-transform: translateX(-10%);
                transform: translateX(-10%);
}
}

.CourseContent_container__1ZXZw {
    width: 100%;
    margin-top: 200px;
}
.CourseContent_content__1675n {
    padding: 40px 2.5% 80px 2.5%;
    margin-left: 240px;
}
.CourseContent_content__1675n > h2 {
    color: var(--gray9);
    font-weight: var(--fontWeightSemiBolder);
}
.CourseContent_divider__3ba0A {
    background: var(--gray5);
}
.CourseContent_moduleTitle__10lTE {
    margin: 24px 0;
    color: var(--gray9);
    font-size: var(--subtitleSize2);
    line-height: var(--subtitleLineHeight2);
}
.CourseContent_cardContainer__25V9z {
    display: grid;
    grid-row-gap: 24px;
    row-gap: 24px;
    margin-bottom: 40px;
}
/* .primary {
    display: flex;
    align-items: center;
    justify-content: center;
} */

.Button_small__3YyyL {
    min-width: 132px;
    height: 48px;
    font-size: 20px;
}

.Button_medium__K6-R- {
    min-width: 160px;
    height: 48px;
    font-size: 20px;
    cursor: pointer;
}

.Button_large__jJWDb {
    min-width: 280px;
    height: 48px;
    font-size: 20px;
}

.Button_medium__K6-R-:hover {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
}
.Header_headerContainer__IerSx {
    width: 100%;
    height: 200px;
    position: fixed;
    top: 0;
    z-index: 1;
}

/* Welcome header*/
.Header_coursesProfileHeader__3DXZq{
    height: 100%;
    width: 100%;
    padding: 40px 0 41px 17%; 
    background-image: url('https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/header_bg.png?alt=media&token=79bfbaed-7c57-4d7c-be80-34ab9fc845ce');
    background-size: cover;
    color: var(--gray1);
    text-align: center;
    font-size: var(--subtitleSize2);
    line-height: var(--subtitleLineHeight2);
}
.Header_headerTitle__221qG {
    color: var(--gray1);
    font-size: var(--titleSize3);
    line-height: var(--titleLineHeight2);
    font-weight: var(--fontWeightBolder);
}

/* Course Header */
.Header_header__1ZrvW {
    width: 100%;
    height: 100%;
}

/* Breadcrumb */
.Header_breadcrumb__D85-j {
    padding: 160px 0 0 272px;
}
.AdvancedCard_container__AI2q4 {
    border: 1px solid magenta;
    width: 360px;
    height: 260px;
    font-family: Avenir;
}
.AdvancedCard_img__38NbM {
    height: 96px;
    object-fit: cover; 
}
.AdvancedCard_title__3F_IR {
    font-size: var(--textSize1);
    font-weight: var(--fontWeightBold);
    line-height: var(--subtitleLineHeight2);
    color: var(--gray9);
    margin: 0;
}
.AdvancedCard_description__2bkFV {
    font-size: var(--textSize2);
    line-height: var(--subtitleLineHeight2);
    color: var(--gray7);
}
.PageFrame_pageFrame__lcTKe {
    padding: 0px 0 0 0;
}
.Navbar_navBarContainer__30SHz {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
    border-bottom: 1px solid rgba(217, 217, 217, .1);
    background-color: transparent !important;
}

.Navbar_lightNavBar__20ffm {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;

}

.Navbar_box__1y1eH {

    display: flex;
}

.Navbar_logoLink__21wFL {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Navbar_lightNavBarLinks__2nyKN {
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
}

.Navbar_sectionLink__z8Hhj {
    margin: 0 24px;
    font-size: 18px;
}

.Navbar_lightNavBarLinks__2nyKN a {
    color: white;
    text-decoration: none;
    font-family: 'Avenir';
    font-size: var(--textSize1);
    line-height: var(--subtitleLineHeight2);
    cursor: pointer;
    transition: all 1s ease;
}

.Navbar_lightNavBarLinks__2nyKN a:hover {
    color: #CB007B;
}

.Navbar_lightNavBarLinks__2nyKN a:active,
.Navbar_lightNavBarLinks__2nyKN a:focus {
    color: #CB007B;
    font-weight: bold;
}

.Navbar_lightNavBarButton__2i3HM {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Navbar_menuDrop__1Uyox {
    width: 80vw;
    height: 100vh;
    background-color: white;
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 0 24px 0px;
    box-shadow: 0px 8px 16px rgba(204, 204, 204, 0.25);
    transition: all .5s ease;

}

.Navbar_menuDrop__1Uyox a {
    text-decoration: none;
}

.Navbar_menuDrop__1Uyox h3 {
    color: black;
    font-size: var(--subtitleSize2);
}

.Navbar_menuDropInactive__2vSIo {
    right: -50vh;
    display: none;

}

.Navbar_navBarMobile__kaUoR {
    display: none;

}

.Navbar_closeIcon__iDqss {
    float: right;
    margin-top: 24px;
    margin-right: 24px;
    background-color: var(--gray4);
    border-radius: 50%;
    padding: 6px;

}

.Navbar_tab__1rId_ {
    position: relative;
    display: inline-block;
}

.Navbar_dropDown__2eT8a {
    display: none;
    font-size: 16px !important;
    top: 56px;
}

.Navbar_tab__1rId_:hover .Navbar_dropDown__2eT8a {
    display: block;
    position: absolute;
    z-index: 1;
}

.Navbar_triangle__3fAMy {
    margin-left: 24px;
    width: 0px;
    height: 0px;
    border-right: 14px solid transparent;
    border-bottom: 14px solid white;
    border-left: 14px solid transparent;

}

.Navbar_boxi__NIt4b {
    background-color: white;
    color: #262626;
    width: 380px;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    margin-left: -48px;
    overflow: hidden;
}

.Navbar_boxi__NIt4b a {
    color: #262626 !important;
    padding: 24px 2%;
}

.Navbar_boxi__NIt4b a:hover {
    background-color: #F5F7FC;
}

.Navbar_navBarMobile__kaUoR h3 {
    border-bottom: 1px dashed rgba(75, 85, 99, .3);
    padding-left: 24px;

}

.Navbar_logoColor__2UuCH {
    margin-left: 24px;


}


@media (min-width:320px) and (max-width:800px) {

    .Navbar_logoLink__21wFL {
        justify-items: flex-start;
        margin-left: -30px;
    }

    .Navbar_lightNavBar__20ffm {
        padding: 0 0 0 10%;
    }

    .Navbar_lightNavBarLinks__2nyKN {
        display: none;
    }

    .Navbar_lightNavBarButton__2i3HM {
        display: none;
    }

    .Navbar_navBarMobile__kaUoR {
        display: flex;
        font-size: 24px;
        right: -50vh;
    }

}
.Footer_footerContainer__nVb3H {
    width: 100%;
    z-index: 9;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fpattern.svg?alt=media&token=5808d680-d519-48f0-883d-250130b5826a");
    background-color: #050505;
    background-repeat: no-repeat;
    background-size: cover;
}

.Footer_footerInfo__Gt4TQ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 32px;
}

.Footer_newsletter__GMh07 {
    width: 300px;
    flex-grow: 1;
    padding: 80px 5% 80px;
    display: flex;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    justify-content: flex-end;
}

.Footer_newsletter__GMh07 p {
    margin-top: 80px;
    color: #E3E3E3;
}

.Footer_disclaimer__1nnlg span {
    color: #E3E3E3 !important;
    margin-top: 0px;
}

.Footer_form__32oKm {
    display: flex;
    align-items: center;
}

.Footer_newsletter__GMh07 img {
    width: 240px;
}

.Footer_newsletter__GMh07 input {
    width: 60%;
    height: 48px;
    color: #4B5563;
}

.Footer_newsletter__GMh07 input::-webkit-input-placeholder {
    color: #4B5563;
    padding-left: 16px;
}

.Footer_newsletter__GMh07 input::placeholder {
    color: #4B5563;
    padding-left: 16px;
}

.Footer_btnSecondary__1NgQq {
    background-color: #F5A800;
    height: 48px;
    border-radius: 16px;
    width: 120px;
    border-radius: 0 4px 4px 0;
    border: none;
    color: #050505;
    cursor: pointer;
}

.Footer_media__3jzU1 {
    width: 300px;
    flex-grow: 1;
    padding: 80px 5% 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Footer_redes__32FqQ {
    display: flex;
    grid-gap: 24px;
    gap: 24px;
    margin-bottom: 16px;
}

.Footer_media__3jzU1 p {
    color: #E3E3E3;

}

.Footer_tab__2i4XQ {
    color: #E3E3E3 !important;

}

.Footer_terms__35FgJ {
    display: flex;
    grid-gap: 64px;
    gap: 64px;
    font-family: LarseitLight;

}

.Footer_disclaimer__1nnlg {
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 0px 5%;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, .1);
    font-size: 16px;

}

.Footer_sections__1a_Mq {
    width: 300px;
    flex-grow: 1;
    padding: 80px 5% 0px;
    color: #E3E3E3;

}

.Footer_sections__1a_Mq a:hover {
    color: #CB007B !important;
}

@media(max-width:900px) {
    .Footer_newsletter__GMh07 {
        padding: 80px 5% 0px;
    }

    .Footer_disclaimer__1nnlg {

        font-size: 14px;

    }

}

/* .logo {
    width: 200px;
}

.footerContainer>a {
    color: var(--gray8);
    text-decoration: none !important;
}

.footerContainer>a:hover {
    color: var(--gray1);
    text-decoration: none !important;

}

.copyrightSection {
    padding: 0 5%;
    width: 100%;
    height: 56px;
    display: flex;
    font-size: var(--textSize2);
    line-height: var(--subtitleLineHeight2);
    border-top: 1px solid #E3E3E3;
    color: var(--gray8);
}

.copyrightContent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.noticeOfPrivacyLink {
    text-decoration: none;
    color: var(--gray8)
} */

/* .footerContain {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.footerLinkWrapper {
    display: flex;
    justify-content: space-around;
    width:80%;
}
.footerLinkSection {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 48px;
}
.footerLinkSection > h3 {
    margin-bottom: 24px;
    color: var(--gray2);
    font-size: var(--subtitleSize2);
    font-weight: var(--fontWeightSemiBold);
    line-height: var(--subtitleLineHeight2);
}
.footerLinkSection > a {
    text-decoration: none;
    margin-bottom: 24px;
    color: var(--gray7);
    font-size: var(--textSize1);
    line-height: var(--subtitleLineHeight2);
}
.footerLinkSection > a:hover{
    color: var(--gray1);
}
.contactSection {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.contactSection > a {
    color: var(--gray7);
}
.contactSection > a:hover {
    color: var(--gray1);
}
.copyrightSection {
    width: 100%;
    height: 56px;
    display: flex;
    color:var(--gray7);
    background: var(--gray9);
    font-size: var(--textSize2);
    line-height: var(--subtitleLineHeight2);
    border-top: 1px solid var(--gray8);
}
.copyrightContent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.noticeOfPrivacyLink {
    text-decoration: none;
    color: var(--gray7)
} */
/* Laptop Web */
/* @media (max-width:768px) {
    .copyrightSection {
        font-size: var(--textSize2);
    }
} */

/* Mobile */
/* @media (min-width:320px) and (max-width:600px) {
    .footerContain {
        padding: 40px 0 50px 24px;
        flex-direction: column;
        align-items: flex-start;
    }

    .logoFooter {
        display: flex;
        align-self: flex-start;
        justify-content: flex-start;
    }

    .footerLinkWrapper {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 0px 13%;
        width: 95%;
        margin-top: 40px;
        padding: 0;
    }

    .footerLinkSection {
        margin: 0;
    }

    .copyrightSection {
        height: 69px;
        font-size: var(--textSize2);
        line-height: var(--textLineHeight2);
    }

    .copyrightContent {
        align-items: flex-start;
        justify-content: flex-start;
        padding: 10px 0px 0 12px;
    }

    .copyrightSection span:last-child {
        display: none;
    }
} */
.CoursesProfile_coursesProfileContainer__3uRw0 {
    margin-top: 240px;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
}

/* Content */
.CoursesProfile_coursesProfileContent__3Im9M {
    margin-left: 240px;
    padding-left: 3%;
}
.CoursesProfile_contentTitle__1j-B- {
    margin-bottom: 24px;
    color: var(--gray10);
    font-size: var(--subtitleSize1);
    line-height: var(--subtitleLineHeight2);
    font-weight: var(--fontWeightSemiBold);
}

/* New profile*/

.CoursesProfile_newProfile__VN2tw  {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.CoursesProfile_newProfileTitle__MOnRv {
    color: var(--gray7);
    font-size: var(--subtitleSize2);
    line-height: var(--subtitleLineHeight2);
    font-weight: var(--fontWeightBold);
}

/* cards */
.CoursesProfile_cardWrapper__1uCvq{
    grid-gap: 25px;
    gap: 25px;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width:1018px) {
    .CoursesProfile_cardWrapper__1uCvq{
        justify-content: center;
    }
}
.NoticeOfPrivacy_NoticeOfPrivacyContainer__MGv8U{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    color: var(--gray8);
    font-size: var(--subtitleSize2);
    line-height: var(--subtitleLineHeight2);
    padding: 120px 5%;
}
.NoticeOfPrivacy_NoticeOfPrivacyTitle__Rr0uX {
    text-align: center;
    color:var(--gray8);
    font-family: Paytone One;
    font-size: var(--titleSize3);
    line-height: var(--titleLineHeight2);
    margin-bottom: 48px;
}
.NoticeOfPrivacy_NoticeOfPrivacyContent__3FXN8 > p {
    margin-bottom: 30px;
}
.NoticeOfPrivacy_PrivacyList__1MaJT > p{
    margin-bottom: 24px;
}
.NoticeOfPrivacy_PrivacyList__1MaJT span{
    color: var(--gray9);
    font-weight: 800; 
}

.PersonalizedProgram_personalizedProgramContainer__1ekZw{
    width: 100%;
    min-height: 94vh;
    display: flex;
    flex-wrap: wrap;
    background: var(--gray1);
}
.PersonalizedProgram_personalizedProgramContent__2AXBB{
    display: flex;
    flex-direction: column;
    width: 400px;
    flex-grow: 1;
    min-height: 100%;
    padding: 160px 5% 0 5%;
}
.PersonalizedProgram_personalizedProgramTitle__1ASfr{
    color: var(--gray8);
    font-weight: var(--fontWeightBolder);
    font-size: var(--titleSize3);
    line-height: var(--titleLineHeight2);
    margin-bottom: 8px;
}
.PersonalizedProgram_personalizedProgramDescription__FSo7b{
    color: var(--gray7);
    font-size: var(--textSize1);
    line-height: var(--subtitleLineHeight2);
    margin-bottom: 40px;
    padding-top: 0;

}
.PersonalizedProgram_personalizedProgramBtn__34Mv0{
    width: 100%;
    margin: 157px 0 40px 0;
    display: flex;
    justify-content: flex-end;
    
}
.PersonalizedProgram_personalizedProgramImgContainer__3BIb_{
    width: 400px;
    flex-grow: 1;
    padding: 20px 20px 20px 0;
    box-sizing: border-box;
   
}
.PersonalizedProgram_personalizedProgramImg__lC_g1{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

@media (min-width: 320px) and (max-width:600px) {
    .PersonalizedProgram_personalizedProgramContent__2AXBB{ 
        padding: 120px 5% 0 5%;
    }   
}
.AditionalForm_aditionalDescription__Rf5zn{
    margin: 16px 0 49px 0;
    color: var(--gray7);
    font-size: var(--subtitleSize2);
    line-height: var(--subtitleLineHeight1);
}
.AditionalForm_personalizedProgramButtons__VK3Dy{ 
    width: 100%;
    margin: 90px 0 40px 0;
    display: flex;
    justify-content: space-between;
}
.ContactForm_contactContainer__2gb6i {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    background-size: cover;
}

.ContactForm_barra__3tmRZ {
    background-color: #050505;
    height: 80px;
}

.ContactForm_contactContent__28wHF {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 4%;
    height: auto;
    border-radius: 16px;
    overflow: hidden;
    color: #050505;
}

.ContactForm_contactTitle__33LB1 {
    color: #050505;
    font-weight: var(--fontWeightBolder);
    font-size: var(--titleSize3);
    line-height: var(--titleLineHeight2);
}

.ContactForm_contactDescription__dFql7 {
    color: #050505;
    font-size: var(--textSize1);
    line-height: var(--subtitleLineHeight2);
    margin-bottom: 40px;
    padding-top: 0;
}

.ContactForm_contactInput__1JcBo {
    margin-bottom: 10px;
    height: 40px;
    color: black;
    border-radius: 8px !important;
}


.ContactForm_contactInput__1JcBo::-webkit-input-placeholder {
    color: #D0D0D0;
    font-family: AvenirBook;
    font-size: 16px;
}


.ContactForm_contactInput__1JcBo::placeholder {
    color: #D0D0D0;
    font-family: AvenirBook;
    font-size: 16px;
}


.ContactForm_contactBtn__12BuO {
    align-self: flex-start;
}

.ContactForm_contactImgContainer__2OnHa {
    width: 400px;
    flex-grow: 1;
    padding: 20px 20px 20px 0;
    box-sizing: border-box;
    min-height: 100vh;
    background-size: cover;
    background-position: right;
    background-image: url("https://images.pexels.com/photos/3756042/pexels-photo-3756042.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");

}

@media(max-width:900px) {
    .ContactForm_contactContainer__2gb6i {
        flex-wrap: wrap;
        height: auto;
        padding: 80px 5%;
    }

    .ContactForm_contactImgContainer__2OnHa {
        display: none;
    }

    .ContactForm_contactContent__28wHF {
        width: 100%;
    }

    .ContactForm_contactTitle__33LB1 {
        font-size: 32px;
        line-height: 40px;
    }
}
.FrequentlyAskedQuestions_questions__QF5aF {
    padding: 160px 10% 80px 10%;
    font-size: 18px;
    background-color: var(--gray1);
}

.FrequentlyAskedQuestions_questions__QF5aF p {
    color: #2D3035;
}

.FrequentlyAskedQuestions_title__d36-C {
    color: black;
    font-size: var(--titleSize2);
    font-weight: var(--fontWeightBolder);
}

.FrequentlyAskedQuestions_questions__QF5aF h3 {
    font-size: 24px;
    color: black;
}

.FrequentlyAskedQuestions_space__1kGx1 {
    padding: 120px 0 0 0;
}

.FrequentlyAskedQuestions_boxAsk__1gm6J {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
}

.FrequentlyAskedQuestions_boxAsk__1gm6J div {
    width: 500px;
    flex-grow: 1;
    padding: 2% 4% 2% 0;
}

.FrequentlyAskedQuestions_callAction__2u9EV {
    text-align: center;
    margin-top: 40px;
}
.Welcome_welcomeContainer__2dLrY {
    margin: 0;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    text-align: center;
    display: flex;
    background-size: cover;
    background-color: black;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fportada-pp.png?alt=media&token=1bfb0e19-7dfc-4b9f-8885-2a9c705114ff");
}



.Welcome_title__32oi_ {
    font-size: var(--titleSize1);
    font-family: LarseitBold;
    line-height: 80px;
    margin-bottom: 0px;
    color: #fbfbfb;
    font-weight: 400;

}

.Welcome_btnOutline__34uA- {
    border: 1px solid white;
    border-radius: 4px;
    height: 48px;
    color: white;
    background-color: transparent;
    min-width: 180px;
    margin-left: 24px;
    cursor: pointer;
    transition: all .5s ease;
}

.Welcome_btnOutline__34uA-:hover {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);

}

.Welcome_btnPrimary__35Zpa {
    border-radius: 4px;
    height: 48px;
    background-color: #CB007B;
    min-width: 180px;
    border: none;
    cursor: pointer;
    color: white;
    transition: all .5s ease;
}

.Welcome_btnPrimary__35Zpa:hover {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);

}


.Welcome_subtitleDescription__3Blxw {

    font-weight: lighter;
    line-height: 40px;
    margin-bottom: 32px;
    color: #C0C0C9;
    font-size: var(--subtitleSize1);

}


.Welcome_box__1N7Z8 {
    padding: 0 5%;
    text-align: left;
    display: flex;
    align-items: center;
}



@-webkit-keyframes Welcome_flipInX__2YcJq {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    to {
        -webkit-transform: perspective(400px);
                transform: perspective(400px);
    }
}



@keyframes Welcome_flipInX__2YcJq {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    to {
        -webkit-transform: perspective(400px);
                transform: perspective(400px);
    }
}

.Welcome_flipInX__2YcJq {
    -webkit-backface-visibility: visible !important;
            backface-visibility: visible !important;
    -webkit-animation-name: Welcome_flipInX__2YcJq;
            animation-name: Welcome_flipInX__2YcJq;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
}

@media(max-width:525px) {
    .Welcome_title__32oi_ {
        font-size: 32px;
        line-height: 48px;
    }

    .Welcome_subtitleDescription__3Blxw {
        font-size: 24px;
        line-height: 32px;
        margin-top: 24px;
    }

    .Welcome_btnPrimary__35Zpa {
        width: 100%;
        margin-left: 0px;
    }

    .Welcome_btnOutline__34uA- {
        width: 100%;
        margin-top: 32px;
        margin-left: 0px;
    }

    .Welcome_welcomeContainer__2dLrY {
        background-position: center;
    }
}
/* .clientContainer {
    width: 100%;
    height: auto;
    background: var(--gray1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 0% 48px 0%;
} */

.ClientCarousel_clientContainer__7NVNL {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
}

.ClientCarousel_clientContainer__7NVNL>h2 {
    margin-bottom: 64px;
    color: var(--gray8);
    font-size: var(--titleSize3);
    font-weight: var(--fontWeightBolder);
    line-height: var(--titleLineHeight2);
}

.ClientCarousel_imgClient__1zgFp {
    cursor: pointer;
    transition: all 1s ease;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
}

.ClientCarousel_imgClient__1zgFp:hover {
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -o-filter: grayscale(0%);
}

@media (min-width:320px) and (max-width:600px) {
    .ClientCarousel_clientContainer__7NVNL {
        padding: 80px 5%;
        height: auto;
    }

    .ClientCarousel_clientContainer__7NVNL>h2 {
        font-size: var(--subtitleSize1);
    }
}
.About_aboutContainer__2_yfi {
    width: 100%;
    padding: 64px 5%;
    height: auto;
    align-items: center;
    justify-content: center;
    background-color: #F9FBFF;
    text-align: center;

}

.About_box__3kmAx {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 24px;
    gap: 24px;
    margin-top: 24px;
}

.About_box__3kmAx img {
    width: 80px;

    height: auto;
    max-block-size: 64px;
    object-fit: scale-down;
    opacity: .8;

}

.About_aboutContent__WT4S2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.About_title__2ptNG {
    font-family: LarseitMedium;
    flex-grow: 1;
    color: #262626;
    font-size: 32px;
    line-height: var(--titleLineHeight2);

}

@media(max-width:525px) {
    .About_title__2ptNG {
        font-size: 24px;
        line-height: 40px;
    }

    .About_aboutContainer__2_yfi {
        padding: 80px 5%;
    }

    .About_box__3kmAx img {
        width: 56px;

    }

}
.Benefits_benefitsContainer__EWzVx {
    width: 100%;
    padding: 160px 5%;
    display: flex;
    justify-content: center;
    background: var(--gray1);
    text-align: center;
    grid-gap: 64px;
    gap: 64px;
}

.Benefits_titleDescription__21gOb {
    font-family: LarseitBold;
    color: var(--gray9);
    font-size: 40px;
    line-height: var(--titleLineHeight2);
}


.Benefits_text__3F49P {

    color: #262626;
    font-size: 20px;
}

.Benefits_benefitsContainer__EWzVx img {
    width: 300px;
    height: 500px;
    flex-grow: 1;
    object-fit: contain;
}

.Benefits_benefitsContainer__EWzVx div {
    width: 300px;
    flex-grow: 2;
    text-align: left;

}

/* PC */
@media (max-width: 1050px) {
    .Benefits_benefitsContainer__EWzVx {
        padding: 80px 0%;
    }
}

/* Mobile */
@media (max-width:525px) {
    .Benefits_benefitsContainer__EWzVx {
        flex-wrap: wrap-reverse;
        padding: 80px 5%;
    }

    .Benefits_titleDescription__21gOb {
        font-size: 32px;
        line-height: 40px;
    }

    .Benefits_benefitsContainer__EWzVx img {

        height: auto;

    }
}
.CommentsCarousel_container__3no3q {
    width: 100%;
    padding: 160px 5%;
    display: flex;
    justify-content: center;
    background: var(--gray1);
    text-align: center;
    grid-gap: 64px;
    gap: 64px;
}

.CommentsCarousel_titleDescription__10Zch {
    font-family: LarseitBold;
    color: var(--gray9);
    font-size: 40px;
    line-height: var(--titleLineHeight2);
}




.CommentsCarousel_text__2TB6O {

    color: #262626;
    font-size: 20px;
}

.CommentsCarousel_name__3JDNk {
    color: #4B5563;
}

.CommentsCarousel_container__3no3q img {
    width: 300px;
    height: 500px;
    flex-grow: 1;
    object-fit: contain;
}

.CommentsCarousel_container__3no3q div {
    width: 300px;
    flex-grow: 2;
    text-align: left;

}

.CommentsCarousel_boxs__1GgyR {
    width: 100%;
    height: auto;
    background-color: #CB007B;
    padding: 120px 0%;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fpattern2.png?alt=media&token=7dc221fe-b0fc-4f37-abef-922c312c7cc5");
    background-size: cover;
    background-position: center;


}

.CommentsCarousel_boxs__1GgyR h2 {
    font-family: LarseitBold;
    color: white;
    font-size: 40px;
    line-height: var(--titleLineHeight2);
    text-align: center;
}


.CommentsCarousel_testimonials__3SlT0 {
    padding: 8px 10%;
    display: flex;
    grid-gap: 64px;
    gap: 64px;
    margin-top: 120px;
}

.CommentsCarousel_card__3fl2C {
    background-color: white;
    border-radius: 24px;
    width: 300px;
    flex-grow: 1;
    padding: 2% 4%;
    text-align: center;
    font-size: 16px;
    color: #000;
}

.CommentsCarousel_client__2xAa2 {
    object-fit: cover;
    width: 120px;
    height: 120px;
    border-radius: 80px;
    margin-top: -120px;
    box-shadow: 0px 8px 16px 0px #ABA7A740;


}

.CommentsCarousel_quote__TlVdI {
    width: 64px;
    margin-bottom: 0px;
}

.CommentsCarousel_card__3fl2C h3 {
    margin-top: 24px;
}

@media(max-width:900px) {
    .CommentsCarousel_testimonials__3SlT0 {
        flex-wrap: wrap;
        grid-gap: 120px;
        gap: 120px;
    }

    .CommentsCarousel_container__3no3q {
        padding: 80px 0%;
    }
}



@media(max-width:525px) {
    .CommentsCarousel_boxs__1GgyR h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .CommentsCarousel_container__3no3q {
        padding: 80px 0%;
    }

    .CommentsCarousel_container__3no3q img {

        height: auto;

    }

    .CommentsCarousel_testimonials__3SlT0 {
        grid-gap: 40px;
        gap: 40px;
        margin-top: 40px;
    }

    .CommentsCarousel_container__3no3q {
        flex-wrap: wrap;
        padding: 80px 5%;
    }

    .CommentsCarousel_titleDescription__10Zch {
        font-size: 32px;
        line-height: 40px;
    }

    .CommentsCarousel_boxs__1GgyR {
        padding: 40px 0%;
    }
}
.Login_container__ExBZg {
    display: flex;
    justify-content: center;
}

.Login_card__19sNX {
    max-width: 580px;
    text-align: center;
}

.Login_card__19sNX .Login_title__1S5vb {
    font-size: var(--titleSize3);
    color: var(--gray10);
    font-weight: bold;
    margin: 0 0 16px 0;
}

.Login_card__19sNX .Login_subtitle__2EOH8 {
    font-size: var(--textSize1);
    color: var(--gray8);
    
}

.Login_card__19sNX .Login_buttons__2Erf4 {
    margin-top: 28px;
    display: flex;
    justify-content: space-around;
}

.Login_card__19sNX .Login_google__dBBt1 {
    border: 1px solid #BFBFBF;
    box-sizing: border-box;
    border-radius: 2px;
    background: transparent;
    padding: 6px 60px;
    cursor: pointer;
}

.Login_card__19sNX .Login_google__dBBt1:active{
    background: var(--gray5);
}

.Login_google__dBBt1 span {
    font-weight: 500;
    color:var(--gray10);
}

.Login_card__19sNX .Login_in__2imQX {
    background: #0077B7;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 6px 60px;
    border:none;
    cursor: pointer;
}

.Login_card__19sNX .Login_in__2imQX:active{
    background: var(--gray8);
}

.Login_in__2imQX span {
    font-weight: 500;
    color:var(--gray1);
}

.Login_divider__3gsSb {
    border: 1px solid #F0F0F0;
    width: 100%;
    height: 1px;
    margin:50px 10px;
}

.Login_buttonContainer__1vAky {
    margin: 48px 0 24px 0;
}

.Login_container__ExBZg .Login_ant-form-item-label__23gYZ > label.Login_ant-form-item-required__2uZyq:before {
    content: unset;
 }

.Login_in__2imQX:disabled, .Login_google__dBBt1:disabled {
    opacity: .6;
     pointer-events: none;
 }
.Companies_companiesContainer__1K13H {
    background-color: white;
    padding: 0px;
}


.Companies_header__3wYw2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 100vh;
    color: #fbfbfb;
    background-position: center;
    background-color: black;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Falto-rendimietno.png?alt=media&token=95a0fe21-95cb-40b0-ae2e-b0aa24442abb");
}



.Companies_titleDescription__-D21r {
    font-size: 48px;
    color: #fbfbfb;
    line-height: 100%;
    font-family: LarseitBold;
}

.Companies_companiesInfo__3hJ1r {
    padding: 0 10%;
    text-align: center;
    font-size: 24px;
    color: #d7d7d7;
}

.Companies_companiesInfo__3hJ1r p {
    margin-bottom: 64px;
    color: #C0C0C9;
    ;
}

.Companies_aboutContainer__25Lfw {
    width: 100%;
    padding: 64px 5%;
    height: auto;
    align-items: center;
    justify-content: center;
    background-color: #F9FBFF;
    text-align: center;

}


.Companies_box__3HEy6 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 24px;
    gap: 24px;
    margin-top: 24px;
}

.Companies_box__3HEy6 img {
    width: 80px;

    height: auto;
    max-block-size: 64px;
    object-fit: scale-down;
    opacity: .8;

}


.Companies_aboutContent__36gwn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.Companies_title__NCv5a {
    font-family: LarseitMedium;
    flex-grow: 1;
    color: #262626;
    font-size: 32px;
    line-height: var(--titleLineHeight2);

}

.Companies_ban__3A3Rm {
    background-color: #CB007B;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    margin-bottom: 48px;
}

.Companies_stepsContainer__1t0fN {
    width: 100%;
    padding: 160px 5% 64px 5%;
    display: flex;
    flex-direction: row;
    text-align: center;
    grid-gap: 64px;
    gap: 64px;
    justify-content: space-between;
}

.Companies_head__3c-ZE {
    font-family: LarseitBold;
    color: #050505;
    font-size: 40px;
    line-height: var(--titleLineHeight2);
}

.Companies_text__3ys7V {

    color: #050505;
    font-size: 20px;
}

.Companies_steps__3a_by {
    max-width: 800px;
    flex-grow: 1;
    text-align: left;
    color: #4B5563;
}

.Companies_stepsContainer__1t0fN img {
    width: 500px;
    height: auto;
    margin-right: 5%;
}


.Companies_ideas__1zHlD {
    display: flex;
    padding: 0px 5% 80px 5%;
    width: 100%;
    justify-content: space-between;
    margin-top: 80px;
    grid-gap: 24px;
    gap: 24px;
}

.Companies_ideaItem__n-pqJ {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
    color: #4B5563;
    width: 320px;
    flex-grow: 1;
    border: .5px dashed rgba(75, 85, 90, .3);
    padding: 2%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.Companies_ideaItem__n-pqJ:hover {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
}

.Companies_ideas__1zHlD div:nth-child(1) {
    border-top: 8px solid #CB007B;
}

.Companies_ideas__1zHlD div:nth-child(2) {
    border-top: 8px solid #F5A800;
}

.Companies_ideas__1zHlD div:nth-child(3) {
    border-top: 8px solid #1226AA;
}

.Companies_ideas__1zHlD div:nth-child(4) {
    border-top: 8px solid #CB007B;
}

.Companies_ideas__1zHlD div:nth-child(5) {
    border-top: 8px solid #F5A800;
}

.Companies_ideaItem__n-pqJ img {
    width: 40px;
    height: 40px;

}

.Companies_ideaItem__n-pqJ p {
    margin: 0;
    padding: 0;
}



.Companies_services__1gzrn {
    padding: 120px 5%;
    background-color: #FAFBFE;
}



.Companies_cardItem__Qc1XQ {
    width: 380px;
    height: 360px;
    overflow: hidden;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fpexels-fox-1595391.jpg?alt=media&token=2f33ab5f-549c-464c-8d30-cfb73f77656f");
    background-size: cover;
    cursor: pointer;
    transition: all .3s ease;

}

.Companies_cover__34NX3>div {
    transition: all .3s ease-in-out;
    -webkit-transform: translateY(130px);
            transform: translateY(130px);
    min-width: 100%;
    min-height: 100%;
}

.Companies_show__3ya5Z {
    transition: all .3s;

}

.Companies_cardItem__Qc1XQ:hover .Companies_cover__34NX3>div {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
}

.Companies_cardItem__Qc1XQ:hover .Companies_show__3ya5Z {
    opacity: 0;
}

.Companies_description__3905W {
    opacity: 0;
    transition: all .3s .2s ease-in-out;
    color: #E3E3E3;


}


.Companies_cardItem__Qc1XQ:hover .Companies_description__3905W {
    opacity: 1;
}

.Companies_cardItem__Qc1XQ:hover .Companies_cover__34NX3 h3:before {
    opacity: 0;
}

.Companies_cover__34NX3 {
    width: 100%;
    background: linear-gradient(90.49deg, rgba(5, 5, 5, 0.7) 0.41%, rgba(5, 5, 5, 0.1) 98.96%);
    color: white;

    padding: 4% 12% 4% 4%;
    min-height: 100%;
    transition: all .3s ease-in-out;

}

.Companies_cardItem__Qc1XQ:hover .Companies_cover__34NX3 {
    opacity: 1;
    color: white;
}

.Companies_cover__34NX3 h3 {
    color: white;
    font-family: LarseitBold;
    position: relative;

}

.Companies_cover__34NX3 h3:before {
    content: "Ver más +";
    position: absolute;
    bottom: -30px;
    font-family: LarseitLight;
    font-size: 16px;
    transition: all .3s;
}

.Companies_cardsBox__JRqcE {
    display: flex;
    grid-gap: 24px;
    gap: 24px;
    margin-top: 64px;


}

.Companies_cta__3AdO7 {
    padding: 160px 5%;
    text-align: center;
}

.Companies_cta__3AdO7 img {
    width: 160px;
}

@media (max-width:1280px) {
    .Companies_cardsBox__JRqcE {
        flex-wrap: wrap;
    }
}

@media (max-width:1025px) {
    .Companies_steper__3Te9E {
        flex-wrap: wrap;
    }

    .Companies_titleDescription__-D21r {
        font-size: 32px;
        line-height: 40px;

    }

    .Companies_companiesInfo__3hJ1r {
        font-size: 20px;

    }

    .Companies_companiesInfo__3hJ1r p {
        margin-bottom: 80px;
    }

    .Companies_stepsContainer__1t0fN img {
        flex-grow: 1;
        width: 100%;
    }
}

@media(max-width:900px) {

    .Companies_title__NCv5a,
    .Companies_head__3c-ZE {
        font-size: 32px;
        line-height: 40px;
    }

    .Companies_stepsContainer__1t0fN {
        flex-wrap: wrap;
    }

    .Companies_aboutContainer__25Lfw,
    .Companies_stepsContainer__1t0fN,
    .Companies_cta__3AdO7 {
        padding: 80px 5%;
    }

    .Companies_ideas__1zHlD {
        flex-wrap: wrap;
        margin-top: 0px;
    }

    .Companies_ideaItem__n-pqJ {
        padding-top: 24px;
    }

    .Companies_cardItem__Qc1XQ {
        flex-grow: 1;
    }

}
@-webkit-keyframes Bfluency_wiggle__iSIlj {

    0%,
    7% {
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }

    15% {
        -webkit-transform: rotateZ(-15deg);
                transform: rotateZ(-15deg);
    }

    20% {
        -webkit-transform: rotateZ(10deg);
                transform: rotateZ(10deg);
    }

    25% {
        -webkit-transform: rotateZ(-10deg);
                transform: rotateZ(-10deg);
    }

    30% {
        -webkit-transform: rotateZ(6deg);
                transform: rotateZ(6deg);
    }

    35% {
        -webkit-transform: rotateZ(-4deg);
                transform: rotateZ(-4deg);
    }

    40%,
    100% {
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }
}

@keyframes Bfluency_wiggle__iSIlj {

    0%,
    7% {
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }

    15% {
        -webkit-transform: rotateZ(-15deg);
                transform: rotateZ(-15deg);
    }

    20% {
        -webkit-transform: rotateZ(10deg);
                transform: rotateZ(10deg);
    }

    25% {
        -webkit-transform: rotateZ(-10deg);
                transform: rotateZ(-10deg);
    }

    30% {
        -webkit-transform: rotateZ(6deg);
                transform: rotateZ(6deg);
    }

    35% {
        -webkit-transform: rotateZ(-4deg);
                transform: rotateZ(-4deg);
    }

    40%,
    100% {
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }
}

.Bfluency_bfluencyContainer__a09T7 {
    width: 100%;
    background-color: white;
}


.Bfluency_container__2DhjE {
    display: flex;
    height: 100vh;
    align-items: center;
    padding: 0 5%;
    background-position: center;
    background-size: cover;
    background-color: black;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimage2.png?alt=media&token=c551082e-1f40-49c2-857c-885647e364e7");

}

.Bfluency_info__3kUAE {
    width: 50%;
    color: #C0C0C9;
    padding-top: 20%;
    font-size: 24px;


}


.Bfluency_container__2DhjE h2 {
    color: #fbfbfb;
    font-family: LarseitBold;
    font-size: 48px;
}

.Bfluency_data__3VXuT {
    display: flex;
    grid-gap: 48px;
    gap: 48px;
    color: white;
    margin-top: 64px;
    font-size: 20px;
}



.Bfluency_data__3VXuT div {
    background-size: cover;
    padding-left: 16px;
    padding-top: 8px;
}

.Bfluency_data__3VXuT div:nth-child(1) {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fcircle.png?alt=media&token=24f35e11-4911-4af7-bdbd-91191629e71e");

}

.Bfluency_data__3VXuT div:nth-child(2) {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fcircle2.png?alt=media&token=f8cb7c7e-7b3c-42a9-9b27-47e1e57358d9");

}

.Bfluency_data__3VXuT div:nth-child(3) {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fcircle3.png?alt=media&token=6fb853fd-29ae-4bcd-a563-259a01d34913");

}

.Bfluency_data__3VXuT h3 {
    color: #fbfbfb;
    font-family: LarseitBold;
    margin-bottom: -8px;
    font-size: 36px;
}

.Bfluency_banner__1GrG5 {
    background-color: #CB007B;
    padding: 40px 5%;
    color: white;
    text-align: center;
    font-family: LarseitMedium;
}

.Bfluency_banner__1GrG5 h2 {
    color: white;
    font-size: 28px;

}

.Bfluency_story__3YCpU {
    padding: 120px 10%;
    font-size: 18px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 40px;
    gap: 40px;
}

.Bfluency_storyCard__1cszB {
    width: 450px;
    flex-grow: 1;
    border: 1px solid #E3E3E3;
    padding: 2% 4%;
    border-radius: 16px;
    transition: all .5s ease;
}

.Bfluency_storyCard__1cszB:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
}

.Bfluency_storyCard__1cszB:hover .Bfluency_icon__1YZ3s {
    -webkit-animation: Bfluency_wiggle__iSIlj 2s linear infinite;
            animation: Bfluency_wiggle__iSIlj 2s linear infinite;
}

.Bfluency_icon__1YZ3s {
    width: 56px;
    margin-bottom: 24px;

}


.Bfluency_story__3YCpU p {
    margin: 12px 0;
    color: #4B5563;
    font-size: 18px;

}

.Bfluency_story__3YCpU h2 {
    font-size: 32px;
    text-align: center;
    font-family: LarseitBold;
    color: #F5A800;
}

.Bfluency_arrow__2AUIg {
    width: 80px;
    height: 80px;
}

.Bfluency_steps__39D0t {
    background-color: #CB007B;
    padding: 80px 5%;
}

.Bfluency_steps__39D0t h2 {
    color: white;
    text-align: center;
    font-size: 40px;
    font-family: LarseitBold;
}

.Bfluency_stepsBox__254fP {
    display: flex;
    justify-content: space-around;
    color: white;

}

.Bfluency_box__1wjjq {
    width: 280px;
}

.Bfluency_stepsBox__254fP img {
    width: 40px;
}

.Bfluency_number__3eIgv {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    height: 100px;
}

.Bfluency_number__3eIgv span {
    font-size: 72px;
    font-family: LarseitBold;
}

.Bfluency_number__3eIgv h3 {
    color: white;
    font-family: LarseitBold;
    font-size: 24px;
}

.Bfluency_number__3eIgv .Bfluency_reasons__PXrhq {
    width: 100%;
    padding: 160px 5%;
    font-size: 24px;
    font-family: LarseitLight;

    text-align: center;
    color: #4B5563;
}

.Bfluency_reasons__PXrhq {
    padding: 120px 5%;
}

.Bfluency_reasons__PXrhq h2 {
    font-size: 40px;
    font-family: LarseitBold;
    width: 80%;
    text-align: center;
    margin: 0 auto;

}

.Bfluency_reasons__PXrhq p {
    color: #4B5563;
    text-align: center;
    font-size: 20px;
}

.Bfluency_reasonsBox__2zTGX {
    display: flex;
    justify-content: center;
    grid-gap: 24px;
    gap: 24px;
    margin-top: 64px;
    color: #4B5563;
}

.Bfluency_reasonsBox__2zTGX img {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 80px;
    height: 80px;
    margin-top: 80px;
}

.Bfluency_reasonsBox__2zTGX div {
    width: 40%;
    border: 1px solid #E6E6EA;
    padding: 2%;
    border-radius: 16px;
    box-shadow: 0px 4px 12px 0px rgba(5, 0, 56, 0.05);
}

.Bfluency_stepsBox__254fP {
    display: flex;
    margin-top: 64px;
}

.Bfluency_infoL__AM5nA {
    text-align: left;
    width: 320px;
    flex-grow: 1;
}

.Bfluency_infoR__1_rjZ {
    width: 320px;
    flex-grow: 1;
}

.Bfluency_infoR__1_rjZ img {
    width: 80%;
    height: 400px;
    object-fit: cover;
}

.Bfluency_btnPrimary__12mo6 {
    border-radius: 4px;
    height: 48px;
    background-color: #CB007B;
    min-width: 180px;
    border: none;
    cursor: pointer;
    color: white;
    transition: all .5s ease;
    font-size: 18px;
    font-family: Avenir;
}

.Bfluency_btnPrimary__12mo6:hover {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);

}

@media(max-width:1200px) {
    .Bfluency_storyCard__1cszB {
        width: 400px;
    }

    .Bfluency_story__3YCpU {
        padding: 120px 5%;
    }

    .Bfluency_story__3YCpU p {

        font-size: 16px;

    }

    .Bfluency_story__3YCpU h2 {
        font-size: 24px;
        text-align: center;
        font-family: LarseitBold;
        color: #F5A800;
    }
}

@media(max-width:900px) {
    .Bfluency_info__3kUAE {
        width: 100%;
        padding-bottom: 120px;
    }

    .Bfluency_container__2DhjE {
        align-items: flex-end;
    }

    .Bfluency_data__3VXuT {
        flex-wrap: wrap;
    }

    .Bfluency_info__3kUAE {
        font-size: 20px;

    }

    .Bfluency_container__2DhjE h2,
    .Bfluency_reasons__PXrhq h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .Bfluency_reasonsBox__2zTGX {
        flex-direction: column;
        align-items: center;
        grid-gap: 0px;
        gap: 0px;
        font-size: 20px;
    }

    .Bfluency_reasonsBox__2zTGX div {
        width: 100%;
        padding: 10%;

    }

    .Bfluency_reasonsBox__2zTGX img {
        margin-top: 0px;
    }

    .Bfluency_reasons__PXrhq {
        padding: 80px 5%;
    }

    .Bfluency_reasons__PXrhq p {
        margin-top: 24px;
    }

    .Bfluency_stepsBox__254fP {
        flex-wrap: wrap;
    }

    .Bfluency_infoR__1_rjZ img {
        width: 100%;
        margin-top: 48px;
    }

    .Bfluency_data__3VXuT h3 {
        font-size: 28px;
    }

    .Bfluency_data__3VXuT p {
        font-size: 20px;
    }

    .Bfluency_data__3VXuT {
        grid-gap: 16px;
        gap: 16px
    }


}

@media (max-width:550px) {
    .Bfluency_banner__1GrG5 h2 {
        font-size: 20px;
    }

    .Bfluency_box__1wjjq p {
        font-size: 16px;
    }

    .Bfluency_box__1wjjq {
        width: 100%;
    }

    .Bfluency_story__3YCpU {
        padding: 40px 5%;

    }

    .Bfluency_stepsBox__254fP {
        flex-direction: column;
    }

    .Bfluency_stepsBox__254fP img {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }


    .Bfluency_steps__39D0t {
        padding: 40px 5%;
    }

    .Bfluency_steps__39D0t h2 {
        font-size: 32px;
    }

    .Bfluency_stepsBox__254fP {
        margin-top: 40px;
    }

    .Bfluency_reasons__PXrhq h2 {
        font-size: 32px;
        width: 100%;
    }

    .Bfluency_reasonsBox__2zTGX {
        font-size: 18px;
    }

    .Bfluency_number__3eIgv span {
        font-size: 64px;
    }

    .Bfluency_number__3eIgv h3 {
        color: white;
        font-family: LarseitBold;
        font-size: 18px;
    }
}
.Athlete_companiesContainer__1AHuJ {
    background-color: white;
}


.Athlete_header__3rUwr {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 100vh;
    background-position: center;
    color: #fbfbfb;
    background-color: black;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimage3.png?alt=media&token=20a5acd3-fdca-4e90-90d2-db6afd4bf395");
}



.Athlete_titleDescription__XihuM {
    font-size: 48px;
    color: #fbfbfb;
    font-family: LarseitBold;
    line-height: 100%;

}


.Athlete_companiesInfo__1zIsB {
    padding: 0 10%;
    text-align: center;
    font-size: 24px;
}

.Athlete_companiesInfo__1zIsB p {
    margin-bottom: 64px;
    color: #C0C0C9;
    ;
}

.Athlete_aboutContainer__1QiA7 {
    width: 100%;
    padding: 160px 5%;
    height: auto;
    align-items: center;
    justify-content: center;
    background-color: #CB007B;
    text-align: center;

}

.Athlete_box__2A6-f {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 24px;
    gap: 24px;
    margin-top: 64px;
    justify-content: center;
}

.Athlete_box__2A6-f img {
    width: 80px;
    height: auto;
    object-fit: scale-down;
    opacity: .8;
}

.Athlete_aboutContent__3nWDx {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Athlete_title__Ca9yV {
    font-family: LarseitMedium;
    flex-grow: 1;
    color: var(--gray1);
    font-size: 40px;
    line-height: var(--titleLineHeight2);
}

.Athlete_stepsContainer__3ROIT {
    width: 100%;
    padding: 160px 5% 64px 5%;
    display: flex;
    flex-direction: row;
    text-align: center;
    grid-gap: 64px;
    gap: 64px;
}

.Athlete_head__2WVWf {
    font-family: LarseitBold;
    color: #050505;
    font-size: 40px;
    line-height: var(--titleLineHeight2);

}

.Athlete_text__2In1o {

    color: #050505;
    font-size: 20px;
}

.Athlete_steps__3cHQg {
    max-width: 800px;
    flex-grow: 1;
    text-align: left;
    color: #4B5563;
}

.Athlete_stepsContainer__3ROIT img {
    width: 300px;
    height: 400px;
    flex-grow: 1;
}


.Athlete_ideas__2IF5h {
    display: flex;
    padding: 0px 5% 80px 5%;
    width: 100%;
    justify-content: space-between;
}

.Athlete_ideaItem__3TC5x {
    display: flex;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
    color: #050505;
    width: 280px;
}

.Athlete_ideaItem__3TC5x img {
    width: 40px;
    height: 40px;

}

.Athlete_ideaItem__3TC5x p {
    margin: 0;
    padding: 0;
}



.Athlete_services__SoeXE {
    padding: 120px 5%;
    background-color: #FAFBFE;
}

.Athlete_services__SoeXE h2 {
    text-align: center;
}



.Athlete_cardItem__3fpik {
    width: 380px;
    height: 360px;
    overflow: hidden;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fpexels-fox-1595391.jpg?alt=media&token=2f33ab5f-549c-464c-8d30-cfb73f77656f");
    background-size: cover;
    cursor: pointer;
    transition: all .3s ease;

}

.Athlete_cover__2GriL>div {
    transition: all .3s ease-in-out;
    -webkit-transform: translateY(130px);
            transform: translateY(130px);
    min-width: 100%;
    min-height: 100%;
}

.Athlete_show__2CDCi {
    transition: all .3s;

}

.Athlete_cardItem__3fpik:hover .Athlete_cover__2GriL>div {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
}

.Athlete_cardItem__3fpik:hover .Athlete_show__2CDCi {
    opacity: 0;
}

.Athlete_description__3tcTs {
    opacity: 0;
    transition: all .3s .2s ease-in-out;
    color: #E3E3E3;
}


.Athlete_cardItem__3fpik:hover .Athlete_description__3tcTs {
    opacity: 1;
}

.Athlete_cardItem__3fpik:hover .Athlete_cover__2GriL h3:before {
    opacity: 0;
}

.Athlete_cover__2GriL {
    width: 100%;
    background: linear-gradient(90.49deg, rgba(5, 5, 5, 0.7) 0.41%, rgba(5, 5, 5, 0.1) 98.96%);
    color: white;

    padding: 4% 12% 4% 4%;
    min-height: 100%;
    transition: all .3s ease-in-out;

}

.Athlete_cardItem__3fpik:hover .Athlete_cover__2GriL {
    opacity: 1;
    color: white;
}

.Athlete_cover__2GriL h3 {
    color: white;
    font-family: LarseitBold;
    position: relative;

}

.Athlete_cover__2GriL h3:before {
    content: "Ver más +";
    position: absolute;
    bottom: -30px;
    font-family: LarseitLight;
    font-size: 16px;
    transition: all .3s;
}

.Athlete_cardsBox__1bQ2E {
    display: flex;
    grid-gap: 24px;
    gap: 24px;
    margin-top: 64px;

}

.Athlete_cta__28lCZ {
    padding: 160px 5%;
    text-align: center;
}

.Athlete_cta__28lCZ img {
    width: 160px;
}

.Athlete_cardBox__230cX {
    display: flex;
    flex-wrap: wrap;
    margin-top: 64px;
    justify-content: center;
    grid-gap: 64px;
    gap: 64px;
    margin-bottom: 48px;
}

.Athlete_card__1jKmP {
    border: 1px solid #E2E1E3;
    color: black;
    border-radius: 24px;
    box-shadow: rgba(129, 104, 145, 0.05) 0px 13px 14px;
    width: 400px;

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
    color: #4B5563;
    transition: all .5s ease;
    font-size: 16px;
}

.Athlete_card__1jKmP h3 {
    color: #050505;
    font-size: 24px;
    font-weight: 500;
}

.Athlete_img__3nEAd {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

}

.Athlete_circle__30HT2 {
    height: 100%;
    object-fit: cover;

}

.Athlete_card__1jKmP:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

/* .card:hover .img {
    transform: translateY(-12px);
} */


@media(max-width:900px) {
    .Athlete_titleDescription__XihuM {
        font-size: 32px;
        line-height: 40px;
    }

    .Athlete_companiesInfo__1zIsB {
        font-size: 20PX;
    }

    .Athlete_title__Ca9yV,
    .Athlete_head__2WVWf {
        font-size: 32px;
        line-height: 40px;
    }

    .Athlete_aboutContainer__1QiA7,
    .Athlete_services__SoeXE,
    .Athlete_cta__28lCZ {
        padding: 80px 5%;
    }

    .Athlete_cardsBox__1bQ2E {
        flex-wrap: wrap;
    }

    .Athlete_cardItem__3fpik {
        flex-grow: 1;
    }

}
.Programas_programasContainer__9KuGz {
    display: flex;
    height: 100vh;
    align-items: center;
    padding: 0 5%;
    background-position: center;
    background-size: cover;
    background-color: black;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fprogramas2.png?alt=media&token=ca903715-7497-4a76-ba6f-40a1e9276320");
}


.Programas_container__34Eon {
    width: 100%;
    background-color: white;
}

.Programas_title__1WG9j {
    font-size: 48px;
    color: #fbfbfb;
    font-family: LarseitBold;
    line-height: 100%;

}

.Programas_info__jn8z4 {
    padding: 0 10%;
    text-align: center;
    font-size: 24px;
}

.Programas_info__jn8z4 p {
    margin-bottom: 64px;
    color: #C0C0C9;

}

.Programas_box__1Qq1H {
    width: 100%;
    padding: 160px 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--gray1);
    text-align: center;
    grid-gap: 64px;
    gap: 64px;
}

.Programas_titleDescription__3E_Rh {
    font-family: LarseitBold;
    color: var(--gray9);
    font-size: 40px;
    line-height: var(--titleLineHeight2);
}

.Programas_text__1ArKv {

    color: #262626;
    font-size: 20px;
}

.Programas_cardImg__c8tJx {
    width: 300px;
    transition: all .5s ease;
}

.Programas_cardImg__c8tJx:hover {
    -webkit-transform: translateY(-16px);
            transform: translateY(-16px);
}

@media(max-width:900px) {

    .Programas_title__1WG9j,
    .Programas_titleDescription__3E_Rh {
        font-size: 32px;
        line-height: 40px;
    }

    .Programas_info__jn8z4 {
        font-size: 20px;
        padding: 0 5%;
    }

    .Programas_box__1Qq1H {

        padding: 80px 5%;

    }

    .Programas_programasContainer__9KuGz {

        background-position: right;
    }


}

@media(max-width:550px) {

    .Programas_content__3OXX3 {
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
    }
}
