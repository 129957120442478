.container {
    border: 1px solid magenta;
    width: 360px;
    height: 260px;
    font-family: Avenir;
}
.img {
    height: 96px;
    object-fit: cover; 
}
.title {
    font-size: var(--textSize1);
    font-weight: var(--fontWeightBold);
    line-height: var(--subtitleLineHeight2);
    color: var(--gray9);
    margin: 0;
}
.description {
    font-size: var(--textSize2);
    line-height: var(--subtitleLineHeight2);
    color: var(--gray7);
}