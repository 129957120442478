/* .primary {
    display: flex;
    align-items: center;
    justify-content: center;
} */

.small {
    min-width: 132px;
    height: 48px;
    font-size: 20px;
}

.medium {
    min-width: 160px;
    height: 48px;
    font-size: 20px;
    cursor: pointer;
}

.large {
    min-width: 280px;
    height: 48px;
    font-size: 20px;
}

.medium:hover {
    transform: translateY(-8px);
}