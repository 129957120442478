:root {
  /* Primary Colors */
  --orange1: rgba(255, 242, 232, 1);
  --orange2: rgba(255, 216, 191, 1);
  --orange3: rgba(255, 187, 150, 1);
  --orange4: rgba(237, 116, 74, 1);
  --orange5: rgba(233, 81, 29, 1);
  --orange6: rgba(233, 81, 29, 1);
  --orange7: rgba(210, 73, 26, 1);
  --orange8: rgba(163, 57, 20, 1);
  --orange9: rgba(140, 49, 17, 1);
  --orange10: rgba(70, 24, 9, 1);
  /* Nueutral Color Palette */
  --gray1: rgba(255, 255, 255, 1);
  --gray2: rgba(250, 250, 250, 1);
  --gray3: rgba(245, 245, 245, 1);
  --gray4: rgba(245, 245, 245, 1);
  --gray5: rgba(217, 217, 217, 1);
  --gray6: rgba(217, 217, 217, 1);
  --gray7: rgba(140, 140, 140, 1);
  --gray8: rgba(77, 75, 75, 1);
  --gray9: rgba(38, 38, 38, 1);
  --gray10: rgba(45, 48, 53, 1);
  /* Extra Colors */
  --backgroundLayout: rgba(240, 242, 245, 1);
  --backgroundMenu1: rgba(0, 21, 41, 1);
  --backgroundMenu2: rgba(0, 21, 41, 1);

  /* Font Weights*/
  --fontWeightNormal: 400;
  --fontWeightSemiBold: 500;
  --fontWeightBold: 700;
  --fontWeightSemiBolder: 800;
  --fontWeightBolder: 900;

  /* Font sizes */
  --titleSize1: 60px;
  --titleSize2: 48px;
  --titleSize3: 36px;
  --subtitleSize1: 24px;
  --subtitleSize2: 18px;
  --textSize1: 16px;
  --textSize2: 14px;
  --noteSize: 12px;

  /* Line Height */
  --titleLineHeight1: 64px;
  --titleLineHeight2: 52px;
  --titleLineHeight3: 40px;
  --subtitleLineHeight1: 28px;
  --subtitleLineHeight2: 22px;
  --textLineHeight1: 20px;
  --textLineHeight2: 18px;
  --notesLineHeight: 16px;

  /* Spacing */
  --spacing-06: 54px;
  --spacing-05: 48px;
  --spacing-04: 32px;
  --spacing-03: 24px;
  --spacing-02: 16px;
  --spacing-01: 8px;

  /* Radius */
  --buttonRadius: 20px;
  --navBarRadius: 2px;

  /* Box-shadow */
  --boxShadowLenght: 0px 8px 16px;
  --boxShadowColor: rgba(204, 204, 204, 0.25);
}


@font-face {
  font-family: AvenirBlack;
  src: url("./assets/fonts/AvenirLTStd-Black.otf");
}

@font-face {
  font-family: AvenirBook;
  src: url("./assets/fonts/AvenirLTStd-Book.otf");
}

@font-face {
  font-family: AvenirRoman;
  src: url("./assets/fonts/AvenirLTStd-Roman.otf");
}

@font-face {
  font-family: LarseitLight;
  src: url("./assets/fonts/Larsseit-Light.otf");
}

@font-face {
  font-family: LarseitBold;
  src: url("./assets/fonts/Larsseit-Bold.otf");
}

@font-face {
  font-family: LarseitMedium;
  src: url("./assets/fonts/Larsseit-Medium.otf");
}