.headerContainer {
    width: 100%;
    height: 200px;
    position: fixed;
    top: 0;
    z-index: 1;
}

/* Welcome header*/
.coursesProfileHeader{
    height: 100%;
    width: 100%;
    padding: 40px 0 41px 17%; 
    background-image: url('https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/header_bg.png?alt=media&token=79bfbaed-7c57-4d7c-be80-34ab9fc845ce');
    background-size: cover;
    color: var(--gray1);
    text-align: center;
    font-size: var(--subtitleSize2);
    line-height: var(--subtitleLineHeight2);
}
.headerTitle {
    color: var(--gray1);
    font-size: var(--titleSize3);
    line-height: var(--titleLineHeight2);
    font-weight: var(--fontWeightBolder);
}

/* Course Header */
.header {
    width: 100%;
    height: 100%;
}

/* Breadcrumb */
.breadcrumb {
    padding: 160px 0 0 272px;
}