.welcomeContainer {
    margin: 0;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    text-align: center;
    display: flex;
    background-size: cover;
    background-color: black;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fportada-pp.png?alt=media&token=1bfb0e19-7dfc-4b9f-8885-2a9c705114ff");
}



.title {
    font-size: var(--titleSize1);
    font-family: LarseitBold;
    line-height: 80px;
    margin-bottom: 0px;
    color: #fbfbfb;
    font-weight: 400;

}

.btnOutline {
    border: 1px solid white;
    border-radius: 4px;
    height: 48px;
    color: white;
    background-color: transparent;
    min-width: 180px;
    margin-left: 24px;
    cursor: pointer;
    transition: all .5s ease;
}

.btnOutline:hover {
    transform: translateY(-8px);

}

.btnPrimary {
    border-radius: 4px;
    height: 48px;
    background-color: #CB007B;
    min-width: 180px;
    border: none;
    cursor: pointer;
    color: white;
    transition: all .5s ease;
}

.btnPrimary:hover {
    transform: translateY(-8px);

}


.subtitleDescription {

    font-weight: lighter;
    line-height: 40px;
    margin-bottom: 32px;
    color: #C0C0C9;
    font-size: var(--subtitleSize1);

}


.box {
    padding: 0 5%;
    text-align: left;
    display: flex;
    align-items: center;
}



@keyframes flipInX {
    from {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        animation-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    to {
        transform: perspective(400px);
    }
}

.flipInX {
    backface-visibility: visible !important;
    animation-name: flipInX;
    animation-duration: 1s;
}

@media(max-width:525px) {
    .title {
        font-size: 32px;
        line-height: 48px;
    }

    .subtitleDescription {
        font-size: 24px;
        line-height: 32px;
        margin-top: 24px;
    }

    .btnPrimary {
        width: 100%;
        margin-left: 0px;
    }

    .btnOutline {
        width: 100%;
        margin-top: 32px;
        margin-left: 0px;
    }

    .welcomeContainer {
        background-position: center;
    }
}