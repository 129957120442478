.navBarContainer {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
    border-bottom: 1px solid rgba(217, 217, 217, .1);
    background-color: transparent !important;
}

.lightNavBar {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;

}

.box {

    display: flex;
}

.logoLink {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lightNavBarLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
}

.sectionLink {
    margin: 0 24px;
    font-size: 18px;
}

.lightNavBarLinks a {
    color: white;
    text-decoration: none;
    font-family: 'Avenir';
    font-size: var(--textSize1);
    line-height: var(--subtitleLineHeight2);
    cursor: pointer;
    transition: all 1s ease;
}

.lightNavBarLinks a:hover {
    color: #CB007B;
}

.lightNavBarLinks a:active,
.lightNavBarLinks a:focus {
    color: #CB007B;
    font-weight: bold;
}

.lightNavBarButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.menuDrop {
    width: 80vw;
    height: 100vh;
    background-color: white;
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 0 24px 0px;
    box-shadow: 0px 8px 16px rgba(204, 204, 204, 0.25);
    transition: all .5s ease;

}

.menuDrop a {
    text-decoration: none;
}

.menuDrop h3 {
    color: black;
    font-size: var(--subtitleSize2);
}

.menuDropInactive {
    right: -50vh;
    display: none;

}

.navBarMobile {
    display: none;

}

.closeIcon {
    float: right;
    margin-top: 24px;
    margin-right: 24px;
    background-color: var(--gray4);
    border-radius: 50%;
    padding: 6px;

}

.tab {
    position: relative;
    display: inline-block;
}

.dropDown {
    display: none;
    font-size: 16px !important;
    top: 56px;
}

.tab:hover .dropDown {
    display: block;
    position: absolute;
    z-index: 1;
}

.triangle {
    margin-left: 24px;
    width: 0px;
    height: 0px;
    border-right: 14px solid transparent;
    border-bottom: 14px solid white;
    border-left: 14px solid transparent;

}

.boxi {
    background-color: white;
    color: #262626;
    width: 380px;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    margin-left: -48px;
    overflow: hidden;
}

.boxi a {
    color: #262626 !important;
    padding: 24px 2%;
}

.boxi a:hover {
    background-color: #F5F7FC;
}

.navBarMobile h3 {
    border-bottom: 1px dashed rgba(75, 85, 99, .3);
    padding-left: 24px;

}

.logoColor {
    margin-left: 24px;


}


@media (min-width:320px) and (max-width:800px) {

    .logoLink {
        justify-items: flex-start;
        margin-left: -30px;
    }

    .lightNavBar {
        padding: 0 0 0 10%;
    }

    .lightNavBarLinks {
        display: none;
    }

    .lightNavBarButton {
        display: none;
    }

    .navBarMobile {
        display: flex;
        font-size: 24px;
        right: -50vh;
    }

}