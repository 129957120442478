.programasContainer {
    display: flex;
    height: 100vh;
    align-items: center;
    padding: 0 5%;
    background-position: center;
    background-size: cover;
    background-color: black;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fprogramas2.png?alt=media&token=ca903715-7497-4a76-ba6f-40a1e9276320");
}


.container {
    width: 100%;
    background-color: white;
}

.title {
    font-size: 48px;
    color: #fbfbfb;
    font-family: LarseitBold;
    line-height: 100%;

}

.info {
    padding: 0 10%;
    text-align: center;
    font-size: 24px;
}

.info p {
    margin-bottom: 64px;
    color: #C0C0C9;

}

.box {
    width: 100%;
    padding: 160px 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--gray1);
    text-align: center;
    gap: 64px;
}

.titleDescription {
    font-family: LarseitBold;
    color: var(--gray9);
    font-size: 40px;
    line-height: var(--titleLineHeight2);
}

.text {

    color: #262626;
    font-size: 20px;
}

.cardImg {
    width: 300px;
    transition: all .5s ease;
}

.cardImg:hover {
    transform: translateY(-16px);
}

@media(max-width:900px) {

    .title,
    .titleDescription {
        font-size: 32px;
        line-height: 40px;
    }

    .info {
        font-size: 20px;
        padding: 0 5%;
    }

    .box {

        padding: 80px 5%;

    }

    .programasContainer {

        background-position: right;
    }


}

@media(max-width:550px) {

    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
    }
}