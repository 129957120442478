.base {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(217, 217, 217, 0.1);
    background: var(--gray1);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.content {
    display: flex;
    align-items: center;
}
.title {
    color: var(--gray9);
    font-size: var(--subtitleSize2);
    line-height: var(--subtitleLineHeight2);
    font-weight: var(--fontWeightSemiBold);
    margin: 10px 0 0 0;
}
.subtitle {
    color: var(--gray7);
}
.progressBar {
    min-width: 200px;
    transform: translateX(-35%);
}

/* Content Locked */
.locked {
    opacity: 0.5;
}
.imgLocked::after{
    content: url('https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/locked_icon.svg?alt=media&token=31610d93-8cb1-4104-8890-5b1466f23107');
    position: relative;
    top: -50px;
    height: 0;
    display: flex;
    justify-content: center;
    filter: brightness(0.2);
}

/* tablet */
@media (max-width:908px) {
    .progressBar {
        min-width: 100px;
        transform: translateX(-10%);
}
}
