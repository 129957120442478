.container {
    width: 100%;
    margin-top: 200px;
}
.content {
    padding: 40px 2.5% 80px 2.5%;
    margin-left: 240px;
}
.content > h2 {
    color: var(--gray9);
    font-weight: var(--fontWeightSemiBolder);
}
.divider {
    background: var(--gray5);
}
.moduleTitle {
    margin: 24px 0;
    color: var(--gray9);
    font-size: var(--subtitleSize2);
    line-height: var(--subtitleLineHeight2);
}
.cardContainer {
    display: grid;
    row-gap: 24px;
    margin-bottom: 40px;
}