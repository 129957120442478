.contactContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    background-size: cover;
}

.barra {
    background-color: #050505;
    height: 80px;
}

.contactContent {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 4%;
    height: auto;
    border-radius: 16px;
    overflow: hidden;
    color: #050505;
}

.contactTitle {
    color: #050505;
    font-weight: var(--fontWeightBolder);
    font-size: var(--titleSize3);
    line-height: var(--titleLineHeight2);
}

.contactDescription {
    color: #050505;
    font-size: var(--textSize1);
    line-height: var(--subtitleLineHeight2);
    margin-bottom: 40px;
    padding-top: 0;
}

.contactInput {
    margin-bottom: 10px;
    height: 40px;
    color: black;
    border-radius: 8px !important;
}


.contactInput::placeholder {
    color: #D0D0D0;
    font-family: AvenirBook;
    font-size: 16px;
}


.contactBtn {
    align-self: flex-start;
}

.contactImgContainer {
    width: 400px;
    flex-grow: 1;
    padding: 20px 20px 20px 0;
    box-sizing: border-box;
    min-height: 100vh;
    background-size: cover;
    background-position: right;
    background-image: url("https://images.pexels.com/photos/3756042/pexels-photo-3756042.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");

}

@media(max-width:900px) {
    .contactContainer {
        flex-wrap: wrap;
        height: auto;
        padding: 80px 5%;
    }

    .contactImgContainer {
        display: none;
    }

    .contactContent {
        width: 100%;
    }

    .contactTitle {
        font-size: 32px;
        line-height: 40px;
    }
}