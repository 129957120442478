.companiesContainer {
    background-color: white;
}


.header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 100vh;
    background-position: center;
    color: #fbfbfb;
    background-color: black;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimage3.png?alt=media&token=20a5acd3-fdca-4e90-90d2-db6afd4bf395");
}



.titleDescription {
    font-size: 48px;
    color: #fbfbfb;
    font-family: LarseitBold;
    line-height: 100%;

}


.companiesInfo {
    padding: 0 10%;
    text-align: center;
    font-size: 24px;
}

.companiesInfo p {
    margin-bottom: 64px;
    color: #C0C0C9;
    ;
}

.aboutContainer {
    width: 100%;
    padding: 160px 5%;
    height: auto;
    align-items: center;
    justify-content: center;
    background-color: #CB007B;
    text-align: center;

}

.box {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 64px;
    justify-content: center;
}

.box img {
    width: 80px;
    height: auto;
    object-fit: scale-down;
    opacity: .8;
}

.aboutContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-family: LarseitMedium;
    flex-grow: 1;
    color: var(--gray1);
    font-size: 40px;
    line-height: var(--titleLineHeight2);
}

.stepsContainer {
    width: 100%;
    padding: 160px 5% 64px 5%;
    display: flex;
    flex-direction: row;
    text-align: center;
    gap: 64px;
}

.head {
    font-family: LarseitBold;
    color: #050505;
    font-size: 40px;
    line-height: var(--titleLineHeight2);

}

.text {

    color: #050505;
    font-size: 20px;
}

.steps {
    max-width: 800px;
    flex-grow: 1;
    text-align: left;
    color: #4B5563;
}

.stepsContainer img {
    width: 300px;
    height: 400px;
    flex-grow: 1;
}


.ideas {
    display: flex;
    padding: 0px 5% 80px 5%;
    width: 100%;
    justify-content: space-between;
}

.ideaItem {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    color: #050505;
    width: 280px;
}

.ideaItem img {
    width: 40px;
    height: 40px;

}

.ideaItem p {
    margin: 0;
    padding: 0;
}



.services {
    padding: 120px 5%;
    background-color: #FAFBFE;
}

.services h2 {
    text-align: center;
}



.cardItem {
    width: 380px;
    height: 360px;
    overflow: hidden;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fpexels-fox-1595391.jpg?alt=media&token=2f33ab5f-549c-464c-8d30-cfb73f77656f");
    background-size: cover;
    cursor: pointer;
    transition: all .3s ease;

}

.cover>div {
    transition: all .3s ease-in-out;
    transform: translateY(130px);
    min-width: 100%;
    min-height: 100%;
}

.show {
    transition: all .3s;

}

.cardItem:hover .cover>div {
    transform: translateY(40px);
}

.cardItem:hover .show {
    opacity: 0;
}

.description {
    opacity: 0;
    transition: all .3s .2s ease-in-out;
    color: #E3E3E3;
}


.cardItem:hover .description {
    opacity: 1;
}

.cardItem:hover .cover h3:before {
    opacity: 0;
}

.cover {
    width: 100%;
    background: linear-gradient(90.49deg, rgba(5, 5, 5, 0.7) 0.41%, rgba(5, 5, 5, 0.1) 98.96%);
    color: white;

    padding: 4% 12% 4% 4%;
    min-height: 100%;
    transition: all .3s ease-in-out;

}

.cardItem:hover .cover {
    opacity: 1;
    color: white;
}

.cover h3 {
    color: white;
    font-family: LarseitBold;
    position: relative;

}

.cover h3:before {
    content: "Ver más +";
    position: absolute;
    bottom: -30px;
    font-family: LarseitLight;
    font-size: 16px;
    transition: all .3s;
}

.cardsBox {
    display: flex;
    gap: 24px;
    margin-top: 64px;

}

.cta {
    padding: 160px 5%;
    text-align: center;
}

.cta img {
    width: 160px;
}

.cardBox {
    display: flex;
    flex-wrap: wrap;
    margin-top: 64px;
    justify-content: center;
    gap: 64px;
    margin-bottom: 48px;
}

.card {
    border: 1px solid #E2E1E3;
    color: black;
    border-radius: 24px;
    box-shadow: rgba(129, 104, 145, 0.05) 0px 13px 14px;
    width: 400px;

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
    color: #4B5563;
    transition: all .5s ease;
    font-size: 16px;
}

.card h3 {
    color: #050505;
    font-size: 24px;
    font-weight: 500;
}

.img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

}

.circle {
    height: 100%;
    object-fit: cover;

}

.card:hover {
    transform: scale(1.05);
}

/* .card:hover .img {
    transform: translateY(-12px);
} */


@media(max-width:900px) {
    .titleDescription {
        font-size: 32px;
        line-height: 40px;
    }

    .companiesInfo {
        font-size: 20PX;
    }

    .title,
    .head {
        font-size: 32px;
        line-height: 40px;
    }

    .aboutContainer,
    .services,
    .cta {
        padding: 80px 5%;
    }

    .cardsBox {
        flex-wrap: wrap;
    }

    .cardItem {
        flex-grow: 1;
    }

}