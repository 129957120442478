.container {
    width: 100%;
    padding: 160px 5%;
    display: flex;
    justify-content: center;
    background: var(--gray1);
    text-align: center;
    gap: 64px;
}

.titleDescription {
    font-family: LarseitBold;
    color: var(--gray9);
    font-size: 40px;
    line-height: var(--titleLineHeight2);
}




.text {

    color: #262626;
    font-size: 20px;
}

.name {
    color: #4B5563;
}

.container img {
    width: 300px;
    height: 500px;
    flex-grow: 1;
    object-fit: contain;
}

.container div {
    width: 300px;
    flex-grow: 2;
    text-align: left;

}

.boxs {
    width: 100%;
    height: auto;
    background-color: #CB007B;
    padding: 120px 0%;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fpattern2.png?alt=media&token=7dc221fe-b0fc-4f37-abef-922c312c7cc5");
    background-size: cover;
    background-position: center;


}

.boxs h2 {
    font-family: LarseitBold;
    color: white;
    font-size: 40px;
    line-height: var(--titleLineHeight2);
    text-align: center;
}


.testimonials {
    padding: 8px 10%;
    display: flex;
    gap: 64px;
    margin-top: 120px;
}

.card {
    background-color: white;
    border-radius: 24px;
    width: 300px;
    flex-grow: 1;
    padding: 2% 4%;
    text-align: center;
    font-size: 16px;
    color: #000;
}

.client {
    object-fit: cover;
    width: 120px;
    height: 120px;
    border-radius: 80px;
    margin-top: -120px;
    box-shadow: 0px 8px 16px 0px #ABA7A740;


}

.quote {
    width: 64px;
    margin-bottom: 0px;
}

.card h3 {
    margin-top: 24px;
}

@media(max-width:900px) {
    .testimonials {
        flex-wrap: wrap;
        gap: 120px;
    }

    .container {
        padding: 80px 0%;
    }
}



@media(max-width:525px) {
    .boxs h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .container {
        padding: 80px 0%;
    }

    .container img {

        height: auto;

    }

    .testimonials {
        gap: 40px;
        margin-top: 40px;
    }

    .container {
        flex-wrap: wrap;
        padding: 80px 5%;
    }

    .titleDescription {
        font-size: 32px;
        line-height: 40px;
    }

    .boxs {
        padding: 40px 0%;
    }
}