.coursesProfileContainer {
    margin-top: 240px;
    min-height: fit-content;
}

/* Content */
.coursesProfileContent {
    margin-left: 240px;
    padding-left: 3%;
}
.contentTitle {
    margin-bottom: 24px;
    color: var(--gray10);
    font-size: var(--subtitleSize1);
    line-height: var(--subtitleLineHeight2);
    font-weight: var(--fontWeightSemiBold);
}

/* New profile*/

.newProfile  {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.newProfileTitle {
    color: var(--gray7);
    font-size: var(--subtitleSize2);
    line-height: var(--subtitleLineHeight2);
    font-weight: var(--fontWeightBold);
}

/* cards */
.cardWrapper{
    gap: 25px;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width:1018px) {
    .cardWrapper{
        justify-content: center;
    }
}