/* .clientContainer {
    width: 100%;
    height: auto;
    background: var(--gray1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 0% 48px 0%;
} */

.clientContainer {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
}

.clientContainer>h2 {
    margin-bottom: 64px;
    color: var(--gray8);
    font-size: var(--titleSize3);
    font-weight: var(--fontWeightBolder);
    line-height: var(--titleLineHeight2);
}

.imgClient {
    cursor: pointer;
    transition: all 1s ease;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
}

.imgClient:hover {
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -o-filter: grayscale(0%);
}

@media (min-width:320px) and (max-width:600px) {
    .clientContainer {
        padding: 80px 5%;
        height: auto;
    }

    .clientContainer>h2 {
        font-size: var(--subtitleSize1);
    }
}