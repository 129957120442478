.container {
    display: flex;
    justify-content: center;
}

.card {
    max-width: 580px;
    text-align: center;
}

.card .title {
    font-size: var(--titleSize3);
    color: var(--gray10);
    font-weight: bold;
    margin: 0 0 16px 0;
}

.card .subtitle {
    font-size: var(--textSize1);
    color: var(--gray8);
    
}

.card .buttons {
    margin-top: 28px;
    display: flex;
    justify-content: space-around;
}

.card .google {
    border: 1px solid #BFBFBF;
    box-sizing: border-box;
    border-radius: 2px;
    background: transparent;
    padding: 6px 60px;
    cursor: pointer;
}

.card .google:active{
    background: var(--gray5);
}

.google span {
    font-weight: 500;
    color:var(--gray10);
}

.card .in {
    background: #0077B7;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 6px 60px;
    border:none;
    cursor: pointer;
}

.card .in:active{
    background: var(--gray8);
}

.in span {
    font-weight: 500;
    color:var(--gray1);
}

.divider {
    border: 1px solid #F0F0F0;
    width: 100%;
    height: 1px;
    margin:50px 10px;
}

.buttonContainer {
    margin: 48px 0 24px 0;
}

.container .ant-form-item-label > label.ant-form-item-required:before {
    content: unset;
 }

.in:disabled, .google:disabled {
    opacity: .6;
     pointer-events: none;
 }