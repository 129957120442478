.footerContainer {
    width: 100%;
    z-index: 9;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fpattern.svg?alt=media&token=5808d680-d519-48f0-883d-250130b5826a");
    background-color: #050505;
    background-repeat: no-repeat;
    background-size: cover;
}

.footerInfo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 32px;
}

.newsletter {
    width: 300px;
    flex-grow: 1;
    padding: 80px 5% 80px;
    display: flex;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    justify-content: flex-end;
}

.newsletter p {
    margin-top: 80px;
    color: #E3E3E3;
}

.disclaimer span {
    color: #E3E3E3 !important;
    margin-top: 0px;
}

.form {
    display: flex;
    align-items: center;
}

.newsletter img {
    width: 240px;
}

.newsletter input {
    width: 60%;
    height: 48px;
    color: #4B5563;
}

.newsletter input::placeholder {
    color: #4B5563;
    padding-left: 16px;
}

.btnSecondary {
    background-color: #F5A800;
    height: 48px;
    border-radius: 16px;
    width: 120px;
    border-radius: 0 4px 4px 0;
    border: none;
    color: #050505;
    cursor: pointer;
}

.media {
    width: 300px;
    flex-grow: 1;
    padding: 80px 5% 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.redes {
    display: flex;
    gap: 24px;
    margin-bottom: 16px;
}

.media p {
    color: #E3E3E3;

}

.tab {
    color: #E3E3E3 !important;

}

.terms {
    display: flex;
    gap: 64px;
    font-family: LarseitLight;

}

.disclaimer {
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 0px 5%;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, .1);
    font-size: 16px;

}

.sections {
    width: 300px;
    flex-grow: 1;
    padding: 80px 5% 0px;
    color: #E3E3E3;

}

.sections a:hover {
    color: #CB007B !important;
}

@media(max-width:900px) {
    .newsletter {
        padding: 80px 5% 0px;
    }

    .disclaimer {

        font-size: 14px;

    }

}

/* .logo {
    width: 200px;
}

.footerContainer>a {
    color: var(--gray8);
    text-decoration: none !important;
}

.footerContainer>a:hover {
    color: var(--gray1);
    text-decoration: none !important;

}

.copyrightSection {
    padding: 0 5%;
    width: 100%;
    height: 56px;
    display: flex;
    font-size: var(--textSize2);
    line-height: var(--subtitleLineHeight2);
    border-top: 1px solid #E3E3E3;
    color: var(--gray8);
}

.copyrightContent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.noticeOfPrivacyLink {
    text-decoration: none;
    color: var(--gray8)
} */

/* .footerContain {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.footerLinkWrapper {
    display: flex;
    justify-content: space-around;
    width:80%;
}
.footerLinkSection {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 48px;
}
.footerLinkSection > h3 {
    margin-bottom: 24px;
    color: var(--gray2);
    font-size: var(--subtitleSize2);
    font-weight: var(--fontWeightSemiBold);
    line-height: var(--subtitleLineHeight2);
}
.footerLinkSection > a {
    text-decoration: none;
    margin-bottom: 24px;
    color: var(--gray7);
    font-size: var(--textSize1);
    line-height: var(--subtitleLineHeight2);
}
.footerLinkSection > a:hover{
    color: var(--gray1);
}
.contactSection {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.contactSection > a {
    color: var(--gray7);
}
.contactSection > a:hover {
    color: var(--gray1);
}
.copyrightSection {
    width: 100%;
    height: 56px;
    display: flex;
    color:var(--gray7);
    background: var(--gray9);
    font-size: var(--textSize2);
    line-height: var(--subtitleLineHeight2);
    border-top: 1px solid var(--gray8);
}
.copyrightContent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.noticeOfPrivacyLink {
    text-decoration: none;
    color: var(--gray7)
} */
/* Laptop Web */
/* @media (max-width:768px) {
    .copyrightSection {
        font-size: var(--textSize2);
    }
} */

/* Mobile */
/* @media (min-width:320px) and (max-width:600px) {
    .footerContain {
        padding: 40px 0 50px 24px;
        flex-direction: column;
        align-items: flex-start;
    }

    .logoFooter {
        display: flex;
        align-self: flex-start;
        justify-content: flex-start;
    }

    .footerLinkWrapper {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 0px 13%;
        width: 95%;
        margin-top: 40px;
        padding: 0;
    }

    .footerLinkSection {
        margin: 0;
    }

    .copyrightSection {
        height: 69px;
        font-size: var(--textSize2);
        line-height: var(--textLineHeight2);
    }

    .copyrightContent {
        align-items: flex-start;
        justify-content: flex-start;
        padding: 10px 0px 0 12px;
    }

    .copyrightSection span:last-child {
        display: none;
    }
} */