@keyframes wiggle {

    0%,
    7% {
        transform: rotateZ(0);
    }

    15% {
        transform: rotateZ(-15deg);
    }

    20% {
        transform: rotateZ(10deg);
    }

    25% {
        transform: rotateZ(-10deg);
    }

    30% {
        transform: rotateZ(6deg);
    }

    35% {
        transform: rotateZ(-4deg);
    }

    40%,
    100% {
        transform: rotateZ(0);
    }
}

.bfluencyContainer {
    width: 100%;
    background-color: white;
}


.container {
    display: flex;
    height: 100vh;
    align-items: center;
    padding: 0 5%;
    background-position: center;
    background-size: cover;
    background-color: black;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimage2.png?alt=media&token=c551082e-1f40-49c2-857c-885647e364e7");

}

.info {
    width: 50%;
    color: #C0C0C9;
    padding-top: 20%;
    font-size: 24px;


}


.container h2 {
    color: #fbfbfb;
    font-family: LarseitBold;
    font-size: 48px;
}

.data {
    display: flex;
    gap: 48px;
    color: white;
    margin-top: 64px;
    font-size: 20px;
}



.data div {
    background-size: cover;
    padding-left: 16px;
    padding-top: 8px;
}

.data div:nth-child(1) {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fcircle.png?alt=media&token=24f35e11-4911-4af7-bdbd-91191629e71e");

}

.data div:nth-child(2) {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fcircle2.png?alt=media&token=f8cb7c7e-7b3c-42a9-9b27-47e1e57358d9");

}

.data div:nth-child(3) {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fcircle3.png?alt=media&token=6fb853fd-29ae-4bcd-a563-259a01d34913");

}

.data h3 {
    color: #fbfbfb;
    font-family: LarseitBold;
    margin-bottom: -8px;
    font-size: 36px;
}

.banner {
    background-color: #CB007B;
    padding: 40px 5%;
    color: white;
    text-align: center;
    font-family: LarseitMedium;
}

.banner h2 {
    color: white;
    font-size: 28px;

}

.story {
    padding: 120px 10%;
    font-size: 18px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.storyCard {
    width: 450px;
    flex-grow: 1;
    border: 1px solid #E3E3E3;
    padding: 2% 4%;
    border-radius: 16px;
    transition: all .5s ease;
}

.storyCard:hover {
    transform: scale(1.02);
}

.storyCard:hover .icon {
    animation: wiggle 2s linear infinite;
}

.icon {
    width: 56px;
    margin-bottom: 24px;

}


.story p {
    margin: 12px 0;
    color: #4B5563;
    font-size: 18px;

}

.story h2 {
    font-size: 32px;
    text-align: center;
    font-family: LarseitBold;
    color: #F5A800;
}

.arrow {
    width: 80px;
    height: 80px;
}

.steps {
    background-color: #CB007B;
    padding: 80px 5%;
}

.steps h2 {
    color: white;
    text-align: center;
    font-size: 40px;
    font-family: LarseitBold;
}

.stepsBox {
    display: flex;
    justify-content: space-around;
    color: white;

}

.box {
    width: 280px;
}

.stepsBox img {
    width: 40px;
}

.number {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100px;
}

.number span {
    font-size: 72px;
    font-family: LarseitBold;
}

.number h3 {
    color: white;
    font-family: LarseitBold;
    font-size: 24px;
}

.number .reasons {
    width: 100%;
    padding: 160px 5%;
    font-size: 24px;
    font-family: LarseitLight;

    text-align: center;
    color: #4B5563;
}

.reasons {
    padding: 120px 5%;
}

.reasons h2 {
    font-size: 40px;
    font-family: LarseitBold;
    width: 80%;
    text-align: center;
    margin: 0 auto;

}

.reasons p {
    color: #4B5563;
    text-align: center;
    font-size: 20px;
}

.reasonsBox {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 64px;
    color: #4B5563;
}

.reasonsBox img {
    transform: rotate(90deg);
    width: 80px;
    height: 80px;
    margin-top: 80px;
}

.reasonsBox div {
    width: 40%;
    border: 1px solid #E6E6EA;
    padding: 2%;
    border-radius: 16px;
    box-shadow: 0px 4px 12px 0px rgba(5, 0, 56, 0.05);
}

.stepsBox {
    display: flex;
    margin-top: 64px;
}

.infoL {
    text-align: left;
    width: 320px;
    flex-grow: 1;
}

.infoR {
    width: 320px;
    flex-grow: 1;
}

.infoR img {
    width: 80%;
    height: 400px;
    object-fit: cover;
}

.btnPrimary {
    border-radius: 4px;
    height: 48px;
    background-color: #CB007B;
    min-width: 180px;
    border: none;
    cursor: pointer;
    color: white;
    transition: all .5s ease;
    font-size: 18px;
    font-family: Avenir;
}

.btnPrimary:hover {
    transform: translateY(-8px);

}

@media(max-width:1200px) {
    .storyCard {
        width: 400px;
    }

    .story {
        padding: 120px 5%;
    }

    .story p {

        font-size: 16px;

    }

    .story h2 {
        font-size: 24px;
        text-align: center;
        font-family: LarseitBold;
        color: #F5A800;
    }
}

@media(max-width:900px) {
    .info {
        width: 100%;
        padding-bottom: 120px;
    }

    .container {
        align-items: flex-end;
    }

    .data {
        flex-wrap: wrap;
    }

    .info {
        font-size: 20px;

    }

    .container h2,
    .reasons h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .reasonsBox {
        flex-direction: column;
        align-items: center;
        gap: 0px;
        font-size: 20px;
    }

    .reasonsBox div {
        width: 100%;
        padding: 10%;

    }

    .reasonsBox img {
        margin-top: 0px;
    }

    .reasons {
        padding: 80px 5%;
    }

    .reasons p {
        margin-top: 24px;
    }

    .stepsBox {
        flex-wrap: wrap;
    }

    .infoR img {
        width: 100%;
        margin-top: 48px;
    }

    .data h3 {
        font-size: 28px;
    }

    .data p {
        font-size: 20px;
    }

    .data {
        gap: 16px
    }


}

@media (max-width:550px) {
    .banner h2 {
        font-size: 20px;
    }

    .box p {
        font-size: 16px;
    }

    .box {
        width: 100%;
    }

    .story {
        padding: 40px 5%;

    }

    .stepsBox {
        flex-direction: column;
    }

    .stepsBox img {
        transform: rotate(90deg);
    }


    .steps {
        padding: 40px 5%;
    }

    .steps h2 {
        font-size: 32px;
    }

    .stepsBox {
        margin-top: 40px;
    }

    .reasons h2 {
        font-size: 32px;
        width: 100%;
    }

    .reasonsBox {
        font-size: 18px;
    }

    .number span {
        font-size: 64px;
    }

    .number h3 {
        color: white;
        font-family: LarseitBold;
        font-size: 18px;
    }
}