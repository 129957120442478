@import url('https://fonts.googleapis.com/css2?family=Paytone+One&display=swap');
.NoticeOfPrivacyContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    color: var(--gray8);
    font-size: var(--subtitleSize2);
    line-height: var(--subtitleLineHeight2);
    padding: 120px 5%;
}
.NoticeOfPrivacyTitle {
    text-align: center;
    color:var(--gray8);
    font-family: Paytone One;
    font-size: var(--titleSize3);
    line-height: var(--titleLineHeight2);
    margin-bottom: 48px;
}
.NoticeOfPrivacyContent > p {
    margin-bottom: 30px;
}
.PrivacyList > p{
    margin-bottom: 24px;
}
.PrivacyList span{
    color: var(--gray9);
    font-weight: 800; 
}
