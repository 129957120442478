.siderContainer {
    position: fixed;
    top: 10%;
    left: 0;
    width: 240px;
    height: 100%;
    background: var(--gray10);
    border-radius: 0 24px 0 0;
    z-index: 1;
}
.siderContent {
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px 0 0px 24px;
}
.siderContent img:nth-child(1) {
    margin-bottom: 34px;
    height: 24px;
}
.links {
    display: flex;
    margin-left: 4px;
    text-decoration: none; 
    font-size: var(--subtitleSize2);
    color: var(--gray7);
    margin-bottom: 30px;
}
.links > :first-child {
    margin-right: 16px;
}
.links:last-child {
    color: var(--gray1);
    display: flex;
}
.links:hover {
    color:var(--gray1);
}
.logOut {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 14px;
}
.activeLink {
    color: var(--gray1);
}

/* tablet */
 @media (max-width:840px) {
    .siderContainer {
        top: 16%;
    }
 }

