.aboutContainer {
    width: 100%;
    padding: 64px 5%;
    height: auto;
    align-items: center;
    justify-content: center;
    background-color: #F9FBFF;
    text-align: center;

}

.box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    margin-top: 24px;
}

.box img {
    width: 80px;

    height: auto;
    max-block-size: 64px;
    object-fit: scale-down;
    opacity: .8;

}

.aboutContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-family: LarseitMedium;
    flex-grow: 1;
    color: #262626;
    font-size: 32px;
    line-height: var(--titleLineHeight2);

}

@media(max-width:525px) {
    .title {
        font-size: 24px;
        line-height: 40px;
    }

    .aboutContainer {
        padding: 80px 5%;
    }

    .box img {
        width: 56px;

    }

}